
@font-face {
    font-family: "Pretendard-Regular";
  
    src:
      url("./Pretendard-Regular.otf") format("opentype");
      
  }

@font-face {
  font-family: "Pretendard-SemiBold";
  
  src:
    url("./Pretendard-SemiBold.otf") format("opentype");
}